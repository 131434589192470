import { defineStore } from 'pinia'
import { useCmsFetch } from '../layers/base/composables/useCmsFetch'

export const useWhitelabelStore = defineStore('whitelabelStore', {
  state: () => {
    const config = useRuntimeConfig()

    return {
      host: config.public.defaultHost,
      config: {}
    }
  },
  getters: {
    isWhitelabel() {
      const config = useRuntimeConfig()
      return this.host !== config.public.defaultHost
    }
  },
  actions: {
    setWhitelabelHost(host) {
      this.host = host
    },

    async fetchWhitelabelData(host) {
      const config = useRuntimeConfig()

      const escapedHost = encodeURIComponent(host)

      const url = `${config.public.cmsUrl}/wp-json/dia/get-whitelabel?host=${escapedHost}`

      const { data } = await useCmsFetch(url)

      if (data?.value) {
        this.config = data.value
      }
    },

    setCssVars(colorMode) {
      const r = document.querySelector(':root')
      if (colorMode === '' || colorMode === 'light') {
        r.style.setProperty('--Text', this.config.light_schema.dark)
        r.style.setProperty('--Text-60', this.config.light_schema.dark + '99')
        r.style.setProperty('--Text-50', this.config.light_schema.dark + '80')
        r.style.setProperty('--Page-bg', this.config.light_schema.page_bg)
        r.style.setProperty('--Block-bg', this.config.light_schema.light)
        r.style.setProperty(
          '--Block-stroke',
          this.config.light_schema.dark + '1A'
        )
        r.style.setProperty('--field-bg', this.config.light_schema.light)
        r.style.setProperty(
          '--field-stroke',
          this.config.light_schema.dark + '33'
        )
        r.style.setProperty(
          '--field-stroke-hover',
          this.config.light_schema.dark + '66'
        )
        r.style.setProperty('--CTA', this.config.light_schema.cta)
        r.style.setProperty('--CTA-50', this.config.light_schema.cta + '80')
        r.style.setProperty('--CTA-text', this.config.light_schema.light)
        r.style.setProperty('--bubble-10', this.config.light_schema.dark + '1A')
        r.style.setProperty('--Banner-5', this.config.light_schema.dark + '0D')
        r.style.setProperty('--Box-bg', this.config.light_schema.light + '00')
        r.style.setProperty(
          '--Secondary-CTA',
          this.config.light_schema.cta_secondary
        )
      } else {
        r.style.setProperty('--Text', this.config.dark_schema.light)
        r.style.setProperty('--Text-60', this.config.dark_schema.light + '99')
        r.style.setProperty('--Text-50', this.config.dark_schema.light + '80')
        r.style.setProperty('--Page-bg', this.config.dark_schema.page_bg)
        r.style.setProperty('--Block-bg', this.config.dark_schema.light + '1A')
        r.style.setProperty(
          '--Block-stroke',
          this.config.dark_schema.light + '1A'
        )
        r.style.setProperty('--field-bg', this.config.dark_schema.light + '0D')
        r.style.setProperty(
          '--field-stroke',
          this.config.dark_schema.light + '33'
        )
        r.style.setProperty(
          '--field-stroke-hover',
          this.config.dark_schema.light + '66'
        )
        r.style.setProperty('--CTA', this.config.dark_schema.cta)
        r.style.setProperty('--CTA-50', this.config.dark_schema.cta + '80')
        r.style.setProperty('--CTA-text', this.config.dark_schema.light)
        r.style.setProperty('--bubble-10', this.config.dark_schema.light + '1A')
        r.style.setProperty('--Banner-5', this.config.dark_schema.light + '0D')
        r.style.setProperty('--Box-bg', this.config.dark_schema.light + '0D')
        r.style.setProperty(
          '--Data-sources',
          this.config.dark_schema.light + '0D'
        )
        r.style.setProperty(
          '--Data-sources-hover',
          this.config.dark_schema.light + '1A'
        )
        r.style.setProperty(
          '--Secondary-CTA',
          this.config.dark_schema.cta_secondary
        )
      }
    }
  }
})
