export default function (value, afterComma = 2) {
  if (value === null || value === undefined) return ''
  if (value < 0.01) {
    let ret = ''
    let i = 0
    let afterComma = false
    let fixed = 0

    String(value)
      .split('')
      .forEach((item) => {
        if (afterComma && item != 0 && fixed === 0) {
          fixed = i + 2
        } else if (afterComma) {
          i++
        } else if (item === '.') {
          afterComma = true
        }
      })

    return value.toFixed(fixed)
  }
  return value.toLocaleString('en-US', {
    minimumFractionDigits: afterComma,
    maximumFractionDigits: afterComma
  })
}
