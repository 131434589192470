import { useNewWalletStore } from '~/stores/newWallet'
import { reconnect, watchAccount } from '@wagmi/core'

export default defineNuxtPlugin(async () => {
  const newWalletStore = useNewWalletStore()

  reconnect(newWalletStore.wagmiConfig)

  const unwatch = watchAccount(newWalletStore.wagmiConfig, {
    onChange(account) {
      newWalletStore.account = account

      newWalletStore.getConnectedWalletBalance()
    }
  })
})
