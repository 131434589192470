<template>
  <footer class="footer">
    <div class="footer__top container">
      <NuxtLink to="/" class="logo">
        <img
          :src="logo"
          :height="mainStore.logo.height"
          :alt="mainStore.logo.alt"
        />
        <span class="divider">|</span><span>Oracle Builder</span>
      </NuxtLink>
      <DeployerUiColorModeSwitcher />
    </div>
    <div class="footer__nav container" v-if="nav">
      <nav>
        <ul class="fn">
          <li v-for="group in nav" :key="group.group_name" class="fn__group">
            <div class="fn__title" v-html="group.group_name"></div>

            <ul class="fn__links">
              <li v-for="item in group.items">
                <NuxtLink
                  :to="item.link"
                  class="fn__link"
                  :target="getTarget(item)"
                >
                  <span
                    v-if="item.icon"
                    class="fn__icon"
                    v-html="item.icon"
                  ></span>
                  <span v-html="item.label"></span>
                  <span
                    v-if="item.is_external"
                    class="fn__external"
                    v-html="ExternalLinkSvg"
                  ></span>
                </NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script setup>
import ExternalLinkSvg from '~/assets/images/external-link.svg?raw'
const mainStore = useMainStore()
const whitelabelStore = useWhitelabelStore()

const colorMode = useCookie('colorMode')

const getTarget = (item) => {
  if (item.is_external) {
    return '_blank'
  }
  return ''
}

const logo = computed(() => {
  if (colorMode.value === 'dark') {
    return whitelabelStore.config?.logo_dark || whitelabelStore.config?.logo
  } else {
    return whitelabelStore.config?.logo
  }
})

const nav = computed(() => whitelabelStore.config?.footer_nav?.nav)
</script>

<style lang="scss" scoped>
.footer {
  padding-block: 25px;
  border-top: 1px solid var(--field-stroke, rgba(255, 255, 255, 0.2));
  background: var(--Block-bg, rgba(255, 255, 255, 0.1));
  margin-top: 100px;

  @media (min-width: 1024px) {
    padding-block: 70px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    @media (min-width: 1024px) {
      margin-bottom: 50px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.9rem;
  @media (max-width: 800px) {
    flex-direction: column;
  }

  .divider {
    @media (max-width: 800px) {
      display: none;
    }
  }
}

.fn {
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--Text, #000);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  &__title {
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__group {
    @media (min-width: 420px) and (max-width: 1023px) {
      min-width: 45%;
      flex: 1 1 0;
    }

    @media (max-width: 419px) {
      width: 100%;
      text-align: center;
    }
  }

  &__links {
    list-style-type: none;
    padding: 0;
    opacity: 0.6;
    font-size: 1.5rem;
    min-width: 145px;
    li {
      margin-bottom: 15px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 5px;

    @media (max-width: 419px) {
      justify-content: center;
    }

    &:hover {
      text-decoration: underline;
    }

    :deep(svg) {
      display: block;

      path {
        fill: var(--Text, #000);
      }
    }
  }

  &__icon {
    position: relative;
    top: -1px;

    :deep(svg) {
      width: 12px;
      height: auto;
    }
  }
}
</style>
