export default function (dateString) {
  const currentTime = new Date()
  const targetTime = new Date(dateString)

  const diffInSeconds = Math.floor((currentTime - targetTime) / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  const padZero = (num) => (num < 10 ? '0' : '') + num

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds === 1 ? 'second' : 'seconds'} ago`
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`
  } else if (diffInHours < 24) {
    const hours = diffInHours
    const minutes = diffInMinutes % 60
    const hoursLabel = hours === 1 ? 'hour' : 'hours'
    const minutesLabel = minutes === 1 ? 'minute' : 'minutes'
    return `${hours} ${hoursLabel} ${minutes} ${minutesLabel} ago`
  } else {
    const days = diffInDays
    const daysLabel = days === 1 ? 'day' : 'days'
    return `${days} ${daysLabel} ago`
  }
}
