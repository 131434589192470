<template>
  <div class="wrapper">
    <AppHeader />
    <div class="container">
      <NuxtPage />
    </div>
    <DeployerModalsMain />
    <DeployerFeedMain />
    <AppFooter />
  </div>
</template>

<script setup>
const config = useRuntimeConfig()
const deployerStore = useDeployerStore()
const textsStore = useTextsStore()
const wlStore = useWhitelabelStore()

const [{ data: listTexts }, { data: accountTexts }, { data: deployTexts }] =
  await Promise.all([
    useCmsFetch(
      `${config.public.cmsUrl}/wp-json/wp/v2/oracle-deployer/?slug=deployer-list`
    ),
    useCmsFetch(
      `${config.public.cmsUrl}/wp-json/wp/v2/oracle-deployer/?slug=deployer-account`
    ),
    useCmsFetch(
      `${config.public.cmsUrl}/wp-json/wp/v2/oracle-deployer/?slug=deployer-deploy`
    )
  ])

textsStore.list = listTexts.value[0].custom_fields
textsStore.account = accountTexts.value[0].custom_fields
textsStore.deploy = deployTexts.value[0].custom_fields

const colorMode = useCookie('colorMode')

onMounted(() => {
  if (wlStore.config.host) {
    wlStore.setCssVars(colorMode.value)
  }

  document.body.classList.add('loaded')
})
</script>

<style lang="scss">
:root {
  font-size: 62.5%;
  --color-bg: #fafafa;
  --color-text: #000;
  --font-main: 'Scto', sans-serif;
  --font-secondary: 'DiaType', monospace;
  --Text: #000;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--Page-bg);
  font-size: 1.6rem;
  color: var(--Text);
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  line-height: 120%;
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

@for $i from 0 through 20 {
  .pr#{$i * 5},
  .small-pr#{$i * 5} {
    padding-right: 5px * $i;
  }
  .pl#{$i * 5},
  .small-pl#{$i * 5} {
    padding-left: 5px * $i;
  }
  .pt#{$i * 5},
  .small-pt#{$i * 5} {
    padding-top: 5px * $i;
  }
  .pb#{$i * 5},
  .small-pb#{$i * 5} {
    padding-bottom: 5px * $i;
  }
  .mr#{$i * 5},
  .small-mr#{$i * 5} {
    margin-right: 5px * $i;
  }
  .ml#{$i * 5},
  .small-ml#{$i * 5} {
    margin-left: 5px * $i;
  }
  .mt#{$i * 5},
  .small-mt#{$i * 5} {
    margin-top: 5px * $i;
  }
  .mb#{$i * 5},
  .small-mb#{$i * 5} {
    margin-bottom: 5px * $i;
  }
}

@media all and (min-width: 643px) {
  @for $i from 0 through 20 {
    .medium-pr#{$i * 5} {
      padding-right: 5px * $i;
    }
    .medium-pl#{$i * 5} {
      padding-left: 5px * $i;
    }
    .medium-pt#{$i * 5} {
      padding-top: 5px * $i;
    }
    .medium-pb#{$i * 5} {
      padding-bottom: 5px * $i;
    }
    .medium-mr#{$i * 5} {
      margin-right: 5px * $i;
    }
    .medium-ml#{$i * 5} {
      margin-left: 5px * $i;
    }
    .medium-mt#{$i * 5} {
      margin-top: 5px * $i;
    }
    .medium-mb#{$i * 5} {
      margin-bottom: 5px * $i;
    }
  }
}

@media all and (min-width: 1024px) {
  @for $i from 0 through 20 {
    .large-pr#{$i * 5} {
      padding-right: 5px * $i;
    }
    .large-pl#{$i * 5} {
      padding-left: 5px * $i;
    }
    .large-pt#{$i * 5} {
      padding-top: 5px * $i;
    }
    .large-pb#{$i * 5} {
      padding-bottom: 5px * $i;
    }
    .large-mr#{$i * 5} {
      margin-right: 5px * $i;
    }
    .large-ml#{$i * 5} {
      margin-left: 5px * $i;
    }
    .large-mt#{$i * 5} {
      margin-top: 5px * $i;
    }
    .large-mb#{$i * 5} {
      margin-bottom: 5px * $i;
    }
  }
}

.container {
  max-width: 1140px;
  width: 100%;
  padding-inline: 14px;
  margin-inline: auto;
}

.wrapper {
  padding-top: 130px;

  @media (max-width: 1023px) {
    padding-top: 80px;
  }
}

.overflow-hidden {
  overflow: hidden;
}
</style>
