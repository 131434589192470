<template>
  <div class="color-mode">
    <button
      type="button"
      v-html="LightModeSvg"
      @click.prevent="changeColorMode('light')"
      class="color-mode-button"
      :class="{ active: colorMode === 'light' }"
    ></button>
    <button
      type="button"
      v-html="DarkModeSvg"
      @click.prevent="changeColorMode('dark')"
      class="color-mode-button"
      :class="{ active: colorMode === 'dark' }"
    ></button>
  </div>
</template>

<script setup>
import LightModeSvg from '~/layers/base/assets/icons/light-mode.svg?raw'
import DarkModeSvg from '~/layers/base/assets/icons/dark-mode.svg?raw'

const wlStore = useWhitelabelStore()
const colorMode = useCookie('colorMode')

if (colorMode.value === '') {
  colorMode.value = 'light'
}

const changeColorMode = (mode) => {
  colorMode.value = mode
  wlStore.setCssVars(mode)
}
</script>

<style lang="scss" scoped>
.color-mode {
  border-radius: 15px;
  border: 1px solid var(--field-stroke, rgba(0, 0, 0, 0.2));
  padding: 5px;
  display: flex;
  gap: 5px;
}

.color-mode-button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(svg) {
    path {
      stroke: var(--Text, #000);
    }
  }

  &.active {
    border-radius: 12px;
    background: var(--CTA-10, rgba(61, 110, 246, 0.1));

    :deep(svg) {
      path {
        stroke: var(--CTA, #3d6ef6);
      }
    }
  }
}
</style>
