import { defineStore } from 'pinia'
import {
  arbitrumSepolia,
  mainnet,
  polygon,
  polygonMumbai,
  sepolia
} from 'viem/chains'
import { defineChain, http } from 'viem'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'
import { getBalance, signTypedData } from '@wagmi/core'

// polygonMumbai.rpcUrls.public.http = [
//   'https://polygon-mumbai-bor.publicnode.com'
// ]
// polygonMumbai.rpcUrls.default.http = [
//   'https://polygon-mumbai-bor.publicnode.com'
// ]

const opCelestiaTestnet = defineChain({
  id: 123420111,
  name: 'OP Celestia Raspberry',
  network: 'opcelestia-testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.opcelestia-raspberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.opcelestia-raspberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://opcelestia-raspberry.gelatoscout.com/'
    }
  }
})

const arbitrumBlueberryTestnet = defineChain({
  id: 88153591557,
  name: 'Arbitrum Blueberry',
  network: 'arb-blueberry',
  nativeCurrency: { name: 'CGT', symbol: 'CGT', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.arb-blueberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.arb-blueberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://arb-blueberry.gelatoscout.com/'
    }
  }
})

const polygonBlackberryTestnet = defineChain({
  id: 94204209,
  name: 'Polygon Blackberry',
  network: 'polygon-blackberry',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.polygon-blackberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.polygon-blackberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: '',
      url: 'https://polygon-blackberry.gelatoscout.com/'
    }
  }
})

const chains = [
  mainnet,
  polygon,
  polygonMumbai,
  sepolia,
  arbitrumSepolia,
  opCelestiaTestnet,
  polygonBlackberryTestnet,
  arbitrumBlueberryTestnet
]

export const useNewWalletStore = defineStore('newWalletStore', () => {
  const config = useRuntimeConfig()
  const whitelabelStore = useWhitelabelStore()

  const metadata = {
    name: 'Dia Data',
    description: 'Oracle deployer',
    url: whitelabelStore.host,
    icons: ['https://avatars.githubusercontent.com/u/37784886']
  }

  const wagmiConfig = defaultWagmiConfig({
    chains,
    // transports: {
    //   [mainnet.id]: http(),
    //   [sepolia.id]: http(),
    //   [arbitrumSepolia.id]: http(),
    //   [opCelestiaTestnet.id]: http(),
    //   [polygonBlackberryTestnet.id]: http(),
    //   [arbitrumBlueberryTestnet.id]: http(),
    //   [polygon.id]: http(),
    //   [polygonMumbai.id]: http()
    // },
    projectId: config.public.wcProjectID,
    metadata
  })

  const walletConnectModal = createWeb3Modal({
    wagmiConfig,
    projectId: config.public.wcProjectID,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

  // refs

  const account = ref(null)
  const balance = ref({ formatted: 0, value: 0 })

  // getters

  function getWalletAddress() {
    return account.value ? useAddressFormat(account.value.address) : null
  }

  // actions

  async function signData(chainId, contents, oracleaddress) {
    const signParams = {
      domain: {
        chainId,
        name: 'Oracle Builder',
        verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
        version: '1'
      },
      types: {
        Oracle: [
          { name: 'contents', type: 'string' },
          { name: 'creator', type: 'address' },
          { name: 'oracleaddress', type: 'address' }
        ]
      },
      message: {
        contents,
        creator: account.value.address,
        oracleaddress
      },
      primaryType: 'Oracle'
    }

    console.log(signParams)

    try {
      return await signTypedData(wagmiConfig, { ...signParams })
    } catch (error) {
      console.error(error)

      return null
    }
  }

  async function getConnectedWalletBalance() {
    if (!account.value) return { formatted: 0, value: 0 }
    const balanceResponse = await getBalance(wagmiConfig, {
      address: account.value.address
    })

    balance.value = balanceResponse
    return balanceResponse
  }

  return {
    account,
    getWalletAddress,
    wagmiConfig,
    walletConnectModal,
    signData,
    getConnectedWalletBalance,
    balance
  }
})
